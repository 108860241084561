import React from "react"
import * as styles from "../styles/index.module.css"

import { styled } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

// import imgPuntazul from "../images/puntazul.png"
import imgCarrera from "../images/carrera.jpg"
import imgDespensas from "../images/aviso-privacidad-portada.jpg"
import imgReconocimientos from "../images/reconocimiento.jpg"
import { Helmet } from "react-helmet"
// import Link from 'gatsby-link'
import Layout from "../components/layout"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const AvisoPrivacidad = () => (
  <Layout>
    <Helmet>
      <title>Aries Online - Aviso de privacidad</title>
    </Helmet>
    <section id="AvisoPrivacidad">
      <Box sx={{ flexGrow: 1 }}>
        {/* {console.log(items)} */}
        {/* {items.map((item, index) => ( */}

        <Grid key={1} container className={styles.gridAP}>
          {/* {item.map(item => (
           */}
          <Grid key={"item-title"} item lg={10} md={10} sm={10} xs={11}>
            <h1 className={`${styles.titleAP}  ${ styles.titleMarginAP}`} style={{marginTop: "25px"}}>
              {/* Aries Fortalece tu Patrimonio cumple 20 años Fortaleciendo el
              Patrimonio de las Familias. ¡¡¡Enhorabuena!!! */}
              {/* Aries Fortalece Tu Patrimonio{" "} */}
              Aviso De Privacidad
            </h1>
          </Grid>
          {/* <Grid key={"item-2"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item2"} className={styles.gridItemRightQS}>
              <div
                className={styles.itemDivImgAP}
                style={{
                  borderRadius: "5px",
                  height: "400px",
                  width:"100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("${imgDespensas}")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img2"} src={imgCarrera}/> * / }
              </div>
              {/* <div className={styles.itemDivTextQS}>
                <p className={styles.textRightQS} style={{ marginBlock: "4%" }}>
                   De conformidad con lo establecido en el Artículo 17, fracción II de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares le informamos, que el presente Aviso de Privacidad (en lo sucesivo referido como “Aviso”) establece los términos y condiciones en virtud de los cuales ARIES FORTALECE TU PATRIMONIO S.A. de C.V. (en adelante “GRUPO ARIES”) con domicilio en Blvd. Cuauhtémoc 10237, Zona Río Tijuana, Baja California, C.P. 22010, en su carácter de Responsable (o el Encargado que designe “GRUPO ARIES ”) del uso y protección de los datos personales del Titular y al respecto informa lo que se expone en el presente Aviso de Privacidad.
                </p>
              </div> * /}
            </Item>
          </Grid> */}
          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
               <strong> 1. CONSENTIMIENTO DEL TITULAR. </strong> En caso de que los datos personales recopilados incluyan datos patrimoniales o financieros, mediante la firma del contrato correspondiente, sea en formato impreso, o utilizando medios electrónicos y sus correspondientes procesos para la formación del consentimiento, se llevarán a cabo actos que constituyen el consentimiento expreso y por escrito del titular de los datos; de esta manera otorgando su consentimiento para que “GRUPO ARIES” o sus Encargados realicen transferencias y/o remisiones de datos personales en términos del numeral 5 del presente Aviso. Entendiéndose por datos personales los contemplados en el artículo 3 fracción V, de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y que se describen en el punto 2 del presente aviso. <br></br>
                El usuario titular puede ejercitar sus derechos en relación a la cancelación, rectificación y oposición al tratamiento de sus datos personales, mismo que se hará enviando un correo a la siguiente dirección <strong> <a href="mailto:info@mipatrimonio.com" className={styles.linkMiPatrimonio}> info@mipatrimonio.com </a> </strong> o haciendo llegar a nuestras oficinas su solicitud por escrito, conteniendo nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud; los documentos que acrediten la identidad o, en su caso, la representación legal, la descripción clara y precisa de los datos personales respecto de los que se solicita ejercitar sus derechos, en el domicilio ubicado en Boulevard Cuauhtémoc, numero 10237, Zona Rio Tijuana, Baja California, C.P. 22010, con número telefónico (664) 6 849070, para efectos de la cancelación, rectificación u oposición, de sus datos personales, según sea el caso. Se le dará respuesta dentro de los 15 días siguientes a la fecha en que se reciba su solicitud o, si transcurrido el termino que señala la Ley, no diera respuesta a su solicitud, entonces poda iniciar el procedimiento de protección de derechos, ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong>  2. DATOS PERSONALES QUE RECABAMOS. </strong> Los datos personales que recopilamos en el supuesto de que el titular hubiese dado su consentimiento expreso y por escrito a fines de identificación son: nombre completo, edad, fecha de nacimiento, domicilio completo, código postal teléfono fijo y/o celular, identificación oficial, correo electrónico, Registro Federal del Contribuyente y CURP. “GRUPO ARIES” puede recolectar datos personales del Titular mediante la entrega directa y/o personal por cualquier medio de contacto entre el Titular y el Responsable o sus Encargados. También puede recolectar datos personales de manera indirecta a través de fuentes de acceso público y de otras fuentes disponibles en el mercado.
                </p>
              </div>
            </Item>
          </Grid>


          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong>  3. FINALIDAD DE LOS DATOS PERSONALES. </strong> <br></br>
                <strong> PRIMARIAS: </strong> Promocionar ofertas, beneficios, organización de eventos, atender opiniones quejas y/o sugerencias, evaluar la calidad de nuestros servicios, sorteos mediante redes sociales, generar facturación electrónica correspondiente al consumo del Titular, realizar el trámite y respuesta de la Solicitud de Ejercicio de Derechos ARCO , realizar el trámite y Solicitud de Revocación de Consentimiento, así como con fines de identificación, estadísticos y de análisis internos, información a clientes y consumidores, la distribución del Directorio de Clientes inscritos, reclutamiento y selección de personal; lo anterior, para llevar a cabo las actividades y gestiones enfocadas al cumplimiento de las obligaciones originadas y derivadas de cualquier relación jurídica y comercial que establezcamos con motivo de la prestación de nuestros servicios; administración de aplicaciones y sitios Web; contacto con el cliente, ya sea con el área ventas y/o con el Departamento que sea necesario el conocer y utilizar los datos del Titular; para dar cumplimiento a la obligación de acceso a sus datos personales, se hará previa acreditación de la identidad del titular o personalidad del representante; en caso de que la información proporcionada en su formato de solicitud sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondiente, podremos solicitarle, dentro de los cinco días hábiles siguientes a la recepción de la solicitud, que aporte los elementos o documentos necesarios para dar trámite a la misma. 
                <br></br>
                <strong> SECUNDARIAS. </strong> “GRUPO ARIES” tratará datos personales para otras finalidades como enviar notificación de ofertas, beneficios, eventos, programas, avisos y/o mensajes promocionales; comunicaciones con fines de mercadotecnia, publicitarios o marketing sobre productos y servicios nuevos o existentes; realizar encuestas; estadísticas; estudios de mercado, sobre hábitos de consumo, intereses y comportamiento; realizar programas de beneficios e incentivos; participar en redes sociales, chats y/o foros de discusión; participar en eventos, trivias, concursos, rifas, juegos y sorteos; evaluar la calidad de los servicios; y en general para cualquier actividad encaminada a promover, mantener, mejorar y evaluar nuestros servicios.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 4. VIDEOVIGILANCIA Y FOTOGRAFIA.  </strong>
                 Toda persona que ingrese a las instalaciones del Responsable, podrá ser videograbada por nuestras cámaras de seguridad, asimismo podría ser fotografiada, esto con el fin de llevar a cabo el control de acceso a nuestras instalaciones corporativas. Las videograbaciones y/o fotografías son almacenadas de 20 a 25 días en los inmuebles.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 5. TRANSFERENCIAS Y/O REMISIONES DE DATOS. </strong> “GRUPO ARIES”
                requiere compartir los datos personales del Titular con el objeto de dar cumplimiento a sus obligaciones jurídicas y/o comerciales, para lo cual ha celebrado o celebrará diversos acuerdos comerciales tanto en territorio nacional como en el extranjero. Los receptores de los datos personales, están obligados por virtud del contrato correspondiente, a mantener la confidencialidad de los datos personales suministrados por “GRUPO ARIES” y a observar el presente Aviso. “GRUPO ARIES” y/o sus Encargados podrán transferir los datos personales recolectados del Titular a cualquier otra sociedad del mismo grupo empresarial al que pertenezca el Responsable y/o filiales que operen con los mismos procesos y políticas internas, sea que se encuentren en territorio nacional o en el extranjero para su tratamiento con las mismas finalidades descritas en este Aviso.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 5. TRANSFERENCIAS Y/O REMISIONES DE DATOS. </strong> “GRUPO ARIES” 
                requiere compartir los datos personales del Titular con el objeto de dar cumplimiento a sus obligaciones jurídicas y/o comerciales, para lo cual ha celebrado o celebrará diversos acuerdos comerciales tanto en territorio nacional como en el extranjero. Los receptores de los datos personales, están obligados por virtud del contrato correspondiente, a mantener la confidencialidad de los datos personales suministrados por “GRUPO ARIES” y a observar el presente Aviso. “GRUPO ARIES” y/o sus Encargados podrán transferir los datos personales recolectados del Titular a cualquier otra sociedad del mismo grupo empresarial al que pertenezca el Responsable y/o filiales que operen con los mismos procesos y políticas internas, sea que se encuentren en territorio nacional o en el extranjero para su tratamiento con las mismas finalidades descritas en este Aviso.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 6. LIMITACIÓN DE USO Y DIVULGACIÓN DE LA INFORMACIÓN.  </strong>
                Los datos personales recolectados se encontrarán protegidos por medidas de seguridad administrativas, técnicas y físicas adecuadas contra el daño, pérdida, alteración, destrucción o uso, acceso o tratamiento no autorizados, de conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales y la demás legislación aplicable. No obstante lo señalado anteriormente, “GRUPO ARIES” no garantiza que terceros no autorizados no puedan tener acceso a los sistemas físicos o lógicos de los Titulares o del Responsable o en los documentos electrónicos y archivos almacenados en sus sistemas. En consecuencia, “GRUPO ARIES” no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado. Las leyes y regulaciones de otros países pueden imponer diferentes requerimientos para la protección de la información en general y de los datos personales que se recolectan vía internet, todos los asuntos en relación a este Portal son regidos por las leyes de México. Si el Titular está ubicado en algún país distinto a México y se comunica con “GRUPO ARIES”, deberá tomar en cuenta que cualquier información que el Titular provea será transferida a México, y al momento de ingresar la información, el Titular autoriza esta transferencia y la aceptación del presente Aviso de Privacidad.
                </p>
              </div>
            </Item>
          </Grid>
       
          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 7. RECOLECCIÓN DE DATOS AL NAVEGAR EN SITIOS Y PÁGINAS WEB DE “ARIES FORTALECE TU PATRIMONIO”. </strong>
                Informamos a los Titulares de los datos personales que en nuestras páginas de Internet, y redes sociales en general, utilizamos cookies, web y otras tecnologías a través de las cuales es posible monitorear el comportamiento del Titular como usuario de Internet, para brindarle un mejor servicio y experiencia al navegar en nuestra página, así como ofrecer nuevos productos y servicios basados en sus preferencias. Los datos personales que obtenemos de estas tecnologías de búsqueda son los siguientes: navegador de preferencia del Titular, nombre del servidor, fecha, horario y tiempo de navegación en internet y en nuestros sitios, secciones consultadas y páginas de Internet accedidas cuando se navega en nuestros sitios web.
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemAP}>
              <div className={styles.itemDivTextAP}>
                <p className={styles.textLeftAP}>
                <strong> 8. CAMBIOS AL AVISO. </strong> “GRUPO ARIES”
                podrá actualizar periódicamente el presente Aviso para reflejar los cambios en nuestras prácticas de información. Es responsabilidad del Titular revisar el contenido del Aviso en los siguientes sitios <a href="https://www.mipatrimonio.com" target="_blank"  className={styles.linkMiPatrimonio} > www.mipatrimonio.com </a> El Responsable entenderá que de no expresar lo contrario, significa que el Titular ha leído, entendido y acordado los términos ahí expuestos, lo que constituye su consentimiento a los cambios y/o actualizaciones respecto al tratamiento de sus datos personales. 
                </p>
              </div>
            </Item>
          </Grid>

          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item  key={"item1"} className={styles.gridItemAP}>
              <div className={`${styles.itemDivTextAP } ${styles.lastUpdateTextAP}`} >
              <p className={styles.textLeftAP}>
                Última actualización: 24 de febrero de 2016.
                </p>
              </div>
            </Item>
          </Grid>
          {/* ))} */}
        </Grid>
        {/* ))} */}
      </Box>
    </section>
  </Layout>
)

export default AvisoPrivacidad
